<template>
  <div class="backgrounAll">
    <div class="animate__animated animate__bounce animate__fadeInUp">
      <div class="video-detail-box">
        <div class="centre-content">
          <div class="video-detail-top">
            <h4 style="padding: 40px 0px 20px">{{ detail.title }}</h4>
            <div class="share-box flex-box">
              <p class="auth">讲师：{{ detail.lecturer }}</p>
              <div class="flex-box">
                <p class="learn-num-icon">
                  <img src="../assets/home/learn-num2.png" alt="" />
                </p>
                <p>{{ detail.viewNum }}</p>
              </div>
              <div class="flex-box">
                <p class="share-icon-icon">
                  <img src="../assets/article/share-icon.png" alt="" />
                </p>
                <p>{{ detail.favNum }}</p>
              </div>
            </div>
            <div class="play-video-box">
              <div class="videoPlay">
                <div class="player-container">
                  <div id="artplayer" class="video-player-box"></div>
                </div>
              </div>
              <div class="videoPlayList">
                <h4 style="text-align: center; padding-bottom: 16px">
                  推荐视频
                </h4>
                <div class="catalogue-box">
                  <div
                    class="videoPlayItem hoverFinger"
                    v-for="cateVideosItem in cateVideos"
                    @click="toDetail(cateVideosItem.vid)"
                    :key="cateVideosItem.id"
                  >
                    <p class="videoPlayItem-cover">
                      <img :src="cateVideosItem.coverImg" alt="" />
                    </p>
                    <p class="videoPlayItem-title">
                      {{ cateVideosItem.title }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="video-detail-bottom">
            <div class="video-nav">
              <div class="nav-list">
                <div
                  :class="tabActive == 0 ? 'nav-item-active' : 'nav-item'"
                  @click="tabChange(0)"
                >
                  全部
                </div>
                <div
                  v-for="item in navList"
                  :class="
                    tabActive == item.cateId ? 'nav-item-active' : 'nav-item'
                  "
                  @click="tabChange(item.cateId)"
                >
                  {{ item.cateName }}
                </div>
              </div>
            </div>
            <div class="video-list">
              <div
                class="video-item"
                v-for="videoItem in videoList"
                @click="toDetail(videoItem.vid)"
              >
                <div class="video-cover">
                  <div class="cover-img">
                    <img :src="videoItem.coverImg" alt="" />
                  </div>
                  <div class="cover-info flex-box">
                    <div class="flex-box">
                      <p class="learnNum-img">
                        <img src="../assets/home/learn-num.png" alt="" />
                      </p>
                      <span>{{ videoItem.viewNum }}</span>
                    </div>
                    <div class="flex-box marLeft-16">
                      <p class="shareNum-img">
                        <img src="../assets/home/share-num.png" alt="" />
                      </p>
                      <span>{{ videoItem.favNum }}</span>
                    </div>
                  </div>
                </div>
                <div class="video-info">
                  <p class="video-title">{{ videoItem.title }}</p>
                  <p class="video-introduce">讲师：{{ videoItem.lecturer }}</p>
                  <p class="videoHover">
                    <img src="../assets/home/course-hover-icon.png" alt="" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 分页 -->
        <div class="page-box">
          <p class="homePage hoverFinger" @click="currentChange(1)">首页</p>
          <el-pagination
            :page-size="12"
            background
            layout="prev, pager, next"
            :total="total"
            pager-count:7
            @current-change="currentChange"
            :current-page="currentPage"
          >
          </el-pagination>
          <p
            class="endPage hoverFinger"
            @click="currentChange(Math.ceil(total / perPage))"
          >
            尾页
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Artplayer from "artplayer";
import request from "@/utils/request";
import { localStorageGet } from "@/utils/localStorage";
import { localStorageSet } from "@/utils/localStorage";
export default {
  data() {
    return {
      tabActive: 0,
      cateId: 0,
      vid: "", // 微视频id
      cateVideos: [],
      detail: {},
      navList: [],
      videoList: [],
      pageNum: 1,
      currentPage: 1, // 当前页码
      total: 0,
      perPage: 8, // 每页请求条数
      accountName: "",

      qrcode: "", //二维码
      qrcodeUrl: "", //下载二维码
      shareDialogVisible: false, //分享二维码
      configInfo: null,
    };
  },
  //接口 一进入页面就执行的
  mounted() {
    let configInfo = JSON.parse(localStorageGet("userInfo"));
    this.configInfo = configInfo;

    this.vid = this.$route.params.vid;

    this.getSmallvideoList();
    this.getSmallvideoCate();
    this.getVideoDetail();
    if (configInfo != null) {
      this.getSmallvideoGeturl();
    }
  },
  //事件方法
  methods: {
    currentChange(val) {
      this.currentPage = val;
      // window.scrollTo(0, 0);
      this.getSmallvideoList();
    },
    tabChange(cateId) {
      this.currentPage = 1;
      this.cateId = cateId;
      this.tabActive = cateId;
      this.getSmallvideoList();
    },
    getVideoDetail() {
      //短视频播放地址
      let data = { vid: this.vid };
      request({
        url: "/smallvideo/detail",
        method: "post",
        data,
      })
        .then((res) => {
          this.detail = res.data.detail;
          this.cateVideos = res.data.cateVideos;
          if (this.configInfo == null) {
            this.artplayer = new Artplayer({
              container: "#artplayer",
              url: res.data.detail.playUrl,
              playbackRate: true,
              setting: true, // 设置 （倍速）
              fullscreen: true, //全屏
              // fullscreenWeb: true, //网页全屏
              pip: true, //画中画
              autoplay: true, //自动播放
              poster: res.data.detail.coverImg, //封面图
              backdrop: true, //背景
              theme: "#fff", //进度条颜色
              autoSize: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // /smallvideo/geturl
    getSmallvideoGeturl() {
      //登录后调改播放接口
      let data = { vid: this.vid };
      request({
        url: "/smallvideo/geturl",
        method: "post",
        data,
      })
        .then((res) => {
          this.artplayer = new Artplayer({
            container: "#artplayer",
            url: res.data.ossUrl,
            playbackRate: true,
            setting: true, // 设置 （倍速）
            fullscreen: true, //全屏
            // fullscreenWeb: true, //网页全屏
            pip: true, //画中画
            autoplay: true, //自动播放
            // poster: res.data.detail.coverImg, //封面图
            backdrop: true, //背景
            theme: "#fff", //进度条颜色
            autoSize: true,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getSmallvideoCate() {
      //微视频分类
      let data = {};
      request({
        url: "/smallvideo/cate",
        method: "post",
        data,
      }).then((res) => {
        this.navList = res.data;
      });
    },
    getSmallvideoList() {
      //微视频列表
      let data = {
        cateId: this.cateId,
        pageNum: this.currentPage,
        perPage: this.perPage,
      };
      request({
        url: "/smallvideo/lists",
        method: "post",
        data,
      }).then((res) => {
        this.videoList = res.data.list;
        this.total = res.data.count;
      });
    },
    toDetail(vida) {
      this.vid = vida;
      let vid = vida;
      let pathInfo = this.$router.resolve({
        path: `/videoDetail/${vid}`,
      });
      // window.open(pathInfo.href, "_blank");
      window.location.href = pathInfo.href;
    },
  },
  //注册子组件
  components: {},
  //计算属性,长得是函数的样子，和data的用法一样，必须要有一个return值 代表计算的结果。
  computed: {},
  //过滤器
  filters: {
    filtime(val) {},
  },
};
</script>

<style lang="less" scoped>
h3 {
  font-size: 16px;
  font-weight: bold;
  color: #3b3d41;
}

.video-detail-top {
  .auth {
    color: #6f7174;
    font-size: 14px;
    margin-right: 16px;
  }

  .share-box {
    margin-bottom: 16px;
    font-size: 14px;
    color: #6f7174;
    bottom: 0;

    .learn-num-icon > img {
      // width: 19px;
      height: 13px;
      margin-right: 4px;
    }

    .share-icon-icon > img {
      width: 19px;
      height: 19px;
      margin-left: 16px;
      margin-right: 4px;
    }

    .share {
      color: #4676f8;
      margin-left: 16px;
    }
  }

  .play-video-box {
    display: flex;

    .videoPlay {
      width: 1000px;
      height: 563px;
      background: #d8d8d8;
      border-radius: 8px;
      // background: transparent;
      background-size: cover;

      .player-container {
        background: transparent;
        background-size: cover;

        .video-player-box {
          height: 563px;
          background: transparent;
          background-size: cover;
        }
      }
    }

    .videoPlayList {
      padding: 16px;
      width: 440px;
      height: 530px;
      background: #d8d8d8;
      border-radius: 8px;
      background: #ffffff;
      // overflow: hidden;
      // overflow-y: scroll;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);

      .catalogue-box {
        overflow: hidden;
        overflow-y: scroll;
        height: 490px;
      }

      .videoPlayItem {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        // box-shadow: 0px -2px 14px 0px rgba(139,114,144,0.24);
        // border-radius: 16px;

        .videoPlayItem-cover > img {
          width: 200px;
          height: 113px;
          background: #d8d8d8;
          border-radius: 8px;
          display: block;
        }

        .videoPlayItem-title {
          width: 184px;
          font-size: 14px;
          font-weight: bold;
          color: #303133;
          margin-left: 16px;
          overflow: hidden;
          text-overflow: ellipsis; //文本溢出显示省略号
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
      }
    }

    // 滚动条

    .catalogue-box::-webkit-scrollbar {
      overflow-y: scroll;
    }

    .catalogue-box::-webkit-scrollbar-thumb {
      height: 109px;
      background: #dcdfe6;
      background: #dcdfe6;
      border-radius: 12px;
    }
  }
}

.video-detail-bottom {
  .video-nav {
    padding: 40px 0px;

    .nav-list {
      display: flex;
      // justify-content: center;
      flex-wrap: wrap;
      // height: 40px;

      .nav-item {
        margin-right: 82px;
        font-size: 16px;
        color: #606266;
        cursor: pointer;
        margin-bottom: 16px;
      }

      .nav-item-active {
        margin-right: 82px;
        font-size: 16px;
        color: #4676f8;
        height: 26px;
        border-bottom: 4px solid #4676f8;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        margin-bottom: 16px;
        cursor: pointer;
      }
    }
  }

  .video-list {
    display: flex;
    flex-wrap: wrap;

    .video-item {
      -webkit-transition: all 0.25s ease-in-out;
      -moz-transition: all 0.25s ease-in-out;
      transition: all 0.25s ease-in-out;
      width: 336px;
      height: 275px;
      background: #ffffff;
      box-shadow: 0px 2px 15px 0px rgba(48, 49, 51, 0.25);
      border-radius: 8px;
      margin-right: 32px;
      margin-bottom: 40px;
      cursor: pointer;

      .video-cover {
        .cover-img > img {
          width: 336px;
          height: 190px;
          border-radius: 8px;
          background-color: #6f7174;
          display: block;
        }

        position: relative;
      }

      .cover-info {
        width: 303px;
        background: linear-gradient(
          180deg,
          rgba(54, 54, 56, 0) 0%,
          rgba(54, 54, 56, 0.25) 100%
        );
        border-radius: 0px 0px 8px 8px;
        position: absolute;
        bottom: 0px;
        display: flex;
        padding: 17px 16px 7px;
        font-size: 12px;
        color: #ffffff;
      }

      .video-info {
        padding: 16px;
        position: relative;

        .video-title {
          font-size: 16px;
          color: #3b3d41;
          margin-bottom: 12px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .video-title :hover {
          color: #4676f8;
        }

        .video-introduce {
          font-size: 12px;
          color: #909399;
        }

        .videoHover {
          position: absolute;
          right: 0;
          bottom: -8px;
          display: none;
        }

        .videoHover > img {
          width: 49px;
          height: 48px;
        }
      }
    }

    .video-item:hover .videoHover {
      display: inline-block;
      animation: mymove 2s infinite;
      animation-iteration-count: 1;
      /*设置动画播放次数*/
    }

    @keyframes mymove {
      from {
        opacity: 0;
      }

      to {
        opacity: 100;
      }
    }

    .video-item:nth-child(4n) {
      margin-right: 0px;
    }

    .video-item:hover {
      // box-shadow: 0px 6px 15px 0px rgba(0, 83, 249, 0.15);
      // transform: translateY(-.60rem); //向上浮动了8个像素
      // animation: mymove 2s infinite;
      -webkit-transform: translateY(-5px);
      -moz-transform: translateY(-5px);
      -ms-transform: translateY(-5px);
      transform: translateY(-5px);
    }
  }
}

.el-dialog,
.el-dialog--center {
  border-radius: 20px;

  .qrCode {
    text-align: center;
  }

  .qrCode > img {
    width: 200px;
    height: 200px;
    background-color: #d8d8d8;
  }

  .download-btn {
    width: 120px;
    height: 37px;
    line-height: 37px;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #4676f8;
    font-size: 16px;
    color: #4676f8;
    margin: 0 auto;
  }
}
</style>
