<template>
  <div class="tabDetails">
    <div>
      <div class="type-nav">
        <p @click="typeChange(1)" :class="type == 1 ? 'nav-item-active hoverFinger' : 'nav-item hoverFinger'
          ">
          课程介绍
        </p>
        <p :class="type == 2 ? 'nav-item-active hoverFinger' : 'nav-item hoverFinger'
          " @click="typeChange(2)">
          章节目录
        </p>
        <p :class="type == 3 ? 'nav-item-active hoverFinger' : 'nav-item hoverFinger'
          " @click="typeChange(3)">
          课程素材
        </p>
      </div>
    </div>
    <div class="type-info" v-show="type == 1">
      <!-- 富文本 -->
      <p v-html="courseInfo.courseDesc"></p>
    </div>
    <div class="type-info" v-show="type == 2">
      <div>
        <el-menu default-active="1" class="el-menu-vertical-demo" :collapse="false"
          :default-openeds="courseLessonsOpenMenus" style="background: #f7f9fc">
          <template v-for="(item, index1) in courseLessons">
            <el-menu-item :key="index1" v-if="isMenuLink(item)" @click="toCoursePlay(item)">{{ item.courseTitle
            }}</el-menu-item>
            <el-submenu :key="index1" v-else :index="index1 + ''">
              <template slot="title">
                <span slot="title"><span :class="{ action: item.contentId == menuActionId }">{{
                  item.chapterName
                }}</span></span>
              </template>

              <template v-for="(item2, index2) in mergeMenu(item)">
                <el-menu-item :key="index1 + '-' + index2" v-if="isMenuLink(item2)" @click="toCoursePlay(item2)"><span
                    :class="{ action: item2.contentId == menuActionId }">{{
                      item2.courseTitle
                    }}</span></el-menu-item>

                <el-submenu v-else class="jieBox" :index="index1 + '-' + index2" :key="index2"
                  style="padding: 0px 10px 10px; margin-bottom: 10px">
                  <span slot="title">{{ item2.unitName }}</span>
                  <el-menu-item v-for="(item3, index3) in item2.lessons" :key="index3" @click="toCoursePlay(item3)">
                    <span :class="{ action: item3.contentId == menuActionId }">{{ item3.courseTitle }}</span>
                  </el-menu-item>
                </el-submenu>
                <!-- <el-submenu
                          :index="index1 + '-' + index2"
                          v-for="(item2, index2) in item.lessons"
                          :key="index2"
                          style="padding-left: 10px"
                        >
                          <span slot="title">{{ item2.courseTitle }}</span>
                        </el-submenu> -->
              </template>
            </el-submenu>
          </template>
        </el-menu>
      </div>
    </div>
    <div class="type-info" v-show="type == 3">
      <el-table :data="sucaiList" style="width: 100%">
        <el-table-column fixed prop="resName" label="素材名称" width="260">
        </el-table-column>
        <el-table-column prop="cateName" label="分类" width="110">
        </el-table-column>
        <el-table-column prop="format" label="格式" width="100">
        </el-table-column>
        <el-table-column prop="createdAt" label="上传时间" width="170">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="120">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
            <el-button v-if="configInfo != null" type="text" size="small" @click="downLoad(scope.row)">下载</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import request from "@/utils/request";
import { localStorageGet } from "@/utils/localStorage";
export default {
  props: {
    courseDetail: {
      require: true,
      type: Object,
    },
    courseid: {
      require: true,
    },
    menuActionId: {
      require: false,
    },
  },
  data() {
    return {
      type: 1,
      courseInfo: {},
      courseLessonsOpenMenus: [], //当前展开的章节目录
      courseLessons: [],
      sucaiList: [],
      resId: '', //素材id
      downloadUrl: '', //  下载地址
    };
  },
  watch: {
    courseDetail() {
      this.init();
    },
    courseid() {
      this.getCourseKejian();
    },
  },
  created() {
    this.getCourseKejian();
  },
  mounted() {
    let configInfo = JSON.parse(localStorageGet("userInfo"));
    this.configInfo = configInfo;
  },
  methods: {
    init() {
      this.courseInfo = this.courseDetail.courseInfo;
      this.courseLessons = this.courseDetail.courseLessons;
      this.courseLessonsOpenMenus = this.getMenuIndexList(
        this.courseDetail.courseLessons
      ); // 计算所有的课程目录
    },
    typeChange(type) {
      this.type = type;
    },
    getCourseKejian() {
      if (
        this.courseid == null ||
        this.courseid == undefined ||
        this.courseid == ""
      )
        return;
      let data = { courseId: this.courseid };
      request({
        url: "/course/kejian",
        method: "post",
        data,
      })
        .then((res) => {
          this.sucaiList = res.data.list; //课程素材
          //   this.sucaiTotal = res.data.count;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleClick(row) { // 查看
      let resId = row.resId;
      let pathInfo = this.$router.resolve({
        path: `/materialDetail/${resId}`,
      });
      window.open(pathInfo.href, "_blank");
    },
    downLoad(row) { //下载
      this.resId = row.resId;
      this.getKjainGeturl()
    },
    getKjainGeturl() {   // 获取下载地址
      //获取课件素材的文件地址
      let data = { resId: this.resId };
      request({
        url: "/kejian/geturl",
        method: "post",
        data,
      })
        .then((res) => {
          this.downloadUrl = res.data.downloadUrl;
          window.open(this.downloadUrl, "_blank");
          // window.location.href = this.downloadUrl;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getMenuIndexList(list) {
      // 折叠导航默认展开
      let arr = [];
      for (let i = 0; i < list.length; i++) {
        let item1 = list[i];
        let children = item1.units || item1.lessons;

        if (children && children.length > 0) {
          arr.push(i + "");
          for (let j = 0; j < children.length; j++) {
            let item2 = children[j];

            if (item2.lessons && item2.lessons.length > 0) {
              arr.push(i + "-" + j);
            }
          }
        }
      }
      return arr;
    },
    // 判断是否是课时
    isMenuLink(obj) {
      return "contentId" in obj && "courseId" in obj;
    },
    // 合并章节和课时
    mergeMenu(obj) {
      let list = [];
      if (obj.units instanceof Array) {
        list = list.concat(obj.units);
      }
      if (obj.lessons instanceof Array) {
        list = list.concat(obj.lessons);
      }
      return list;
    },
    toCoursePlay(item) {
      this.$emit("toCoursePlay", item);
    },
  },
};
</script>
<style lang="less" scoped>
.tabDetails {
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  .type-nav {
    display: flex;
    justify-content: center;
    height: 57px;
    line-height: 57px;
    border-bottom: 1px solid #e8e8e8;

    .nav-item {
      font-size: 16px;
      font-weight: bold;
      color: #303133;
      margin-right: 100px;
    }

    .nav-item-active {
      font-size: 16px;
      font-weight: bold;
      color: #4676f8;
      margin-right: 100px;
      border-bottom: 4px solid #4676f8;
    }

    .nav-item:nth-child(3) {
      margin-right: 0px;
    }

    .nav-item-active:nth-child(3) {
      margin-right: 0px;
    }
  }

  .type-info {
    // padding: 24px;
    margin: 24px;

    ::v-deep img {
      width: 100%;
    }
  }

  // .type-info>img {
  //   width: 100%;
  //   border: 1px solid red !important;
  //   width: 750px !important;
  // }

}

// .action {
//     color: red;
// }
</style>