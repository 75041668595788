import axios from 'axios';
import { MessageBox, Message } from 'element-ui';
import store from '@/store';
import { getToken } from '@/utils/auth';
import md5 from 'js-md5';
import { sort_ASCII, checkEmpty } from '@/utils';
import sha256 from 'js-sha256';
import { signKey, requestTimeOut, baseUrl } from '@/settings.js';
import Qs from 'qs';
import { localStorageGet } from '@/utils/localStorage';
import router from '@/router';
// create an axios instance
// console.log('baseUrl', baseUrl)
// axios.defaults.withCredentials = true;
const service = axios.create({
    baseURL: baseUrl, // url = base url + request url
    withCredentials: false,
    // send cookies when cross-domain requests
    timeout: requestTimeOut, // request timeout
    headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
    transformRequest: [(data) => Qs.stringify(data, { indices: true })],
});


// request interceptor
service.interceptors.request.use(
    (config) => {
        // do something before request is sent

        if (store.getters.token) {
            // let each request carry token
            // ['X-Token'] is a custom headers key
            // please modify it according to the actual situation
            let token = getToken();
            // config.headers['Authorization'] = 'Bearer ' + token;
        }

        //动态生成sign
        if (config.method === 'get') {
            const data = JSON.stringify(sort_ASCII(config.params));
            const key = signKey;
            const originKey = `${data}${key}`;
            const sign = md5(originKey);
            config['sign'] = sign;
        }

        if (config.method === 'post') {
            let params = config.data;
            if (config.url == '/upload') {
                // let xltuploadfile = params.get('xltuploadfile')
                // console.log(xltuploadfile);
                // let file = `xltuploadfile=${xltuploadfile}`
                // console.log(file);
                // const originKey = `${file}${signKey}`;
                // const sign = sha256(originKey);
                // config.data.append('sign',sign);
            } else {
                params = sort_ASCII(params);
                let perSignStrArr = [];
                for (let key in params) {
                    // let aone = encodeURI(key) + '=' + encodeURI(params[key]);
                    let aone = key + '=' + params[key];
                    // let value = params[key];
                    // if (typeof value == 'string' && value == '') {
                    //     value = "''"
                    // }
                    // let aone = key + '=' + value;
                    perSignStrArr.push(aone);
                }
                let perSignStr = perSignStrArr.join('&');
                // console.log(perSignStr, 'perSignStr0000000');
                const originKey = `${perSignStr}${signKey}`;
                const sign = sha256(originKey);
                // console.log(config.url,'================================S')
                // console.log(originKey, 1111111111);
                // console.log(sign, 2222222222)
                // console.log(config.url,'================================E')
                // config.headers['sign'] = sign;
                // config['sign'] = sign;
                config.data['sign'] = sign;

            }
            let userInfo = localStorageGet('userInfo');
            if (checkEmpty(userInfo) == false) {
                let orgInfoArr = JSON.parse(userInfo);
                if (checkEmpty(orgInfoArr) == false) {
                    config.headers['Xlt-Id'] = orgInfoArr.accountId;
                    config.headers['Xlt-Token'] = orgInfoArr.token;
                }
            }
        }
        return config;
    },
    (error) => {
        // do something with request error
        console.log('错误错误错误错误错误错误错误错误');
        console.log('错误', error); // for debug
        return Promise.reject(error);
    },
);

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    (response) => {
        const res = response.data;
        const responses = response
        // console.log('888888888888', response.config.url);
        // console.log('9999999999999', res);
        // 二进制数据则直接返回
        if (response.request.responseType === 'blob' || response.request.responseType === 'arraybuffer') {
            return response;
        }
        if (res.code !== 0) {
            if (responses.config.url == '/student/calscore') {//如果是学分重新统计接口，抛出错误msg
                // return res.msg;
                return Promise.reject(res)
            } else {
                Message({
                    message: res.msg,
                    type: 'error',
                    duration: 2 * 1000,
                });

                if (res.code === -100) {
                    store.dispatch('user/refreshOrgToken').then(() => { });
                }
                return Promise.reject(new Error(res.msg || '系统繁忙，请您稍后重试'));
            }


        } else {
            return res;
        }
    },
    (error) => {
        console.log('444444444', error);
        // if(error == 'Network Error'){
        //     console.log('网络请求失败')
        // }else{
        //     console.log(111)
        // }
        // return false
        console.log('err' + error); // for debug
        Message({
            // message: error.msg,
            message: error.msg || '网络请求失败',
            type: 'error',
            duration: 2 * 1000,
        });
        // return Promise.reject(error);
    },
);


export default service;