<template>
  <div class="backgrounAll">
    <div
      class="bookDetail-box animate__animated animate__bounce animate__fadeInUp"
    >
      <div class="centre-content">
        <div class="book-recommend">
          <p class="bookDetail-cover">
            <img :src="bookDetail.coverImg" alt="" />
          </p>
          <div class="bookDetail-info parentLocation">
            <p class="bookDetail-title">{{ bookDetail.bookName }}</p>
            <p class="bookDetail-time">
              {{ bookDetail.bookAuthor }}<span>著</span>
              <span>更新时间：20223-5-15</span>
            </p>
            <div class="flex-box">
              <p class="btn1 hoverFinger">心理学</p>
              <p class="btn2 hoverFinger">人格心理学</p>
            </div>
            <p class="bookDetail-jieshao">{{ bookDetail.bookDesc }}</p>
            <div class="share-box flex-box sonLocation">
              <div class="flex-box">
                <p class="learn-num-icon">
                  <img src="../assets/article/learn-num.png" alt="" />
                </p>
                <p>123654</p>
              </div>
              <div class="flex-box">
                <p class="share-icon-icon">
                  <img src="../assets/article/share-icon.png" alt="" />
                </p>
                <p>1654</p>
              </div>
            </div>
          </div>
          <!-- 扫码阅读 -->
          <div class="qrcode-box" v-if="bookQrcode != ''">
            <p class="qrcode-img"><img :src="bookQrcode" alt="" /></p>
            <p class="qrcode-title">微信扫码阅读</p>
          </div>
        </div>
        <!-- 推荐  -->
        <div class="bookList-recommend" v-if="chapterList.length == 0">
          <div class="flexBox-space-between">
            <p class="recommend-text">相关推荐</p>
            <div class="block">
              <el-pagination
                :page-size="5"
                background
                layout="prev, pager, next"
                :total="total"
                pager-count:7
                @current-change="currentChange"
                :current-page="currentPage"
              >
              </el-pagination>
            </div>
          </div>
          <div class="book-list">
            <div
              class="book-item hoverFinger"
              v-for="bookItem in bookList"
              @click="toDetail(bookItem.cbid)"
            >
              <div class="parentLocation">
                <div class="bookItem-img">
                  <img :src="bookItem.coverImg" alt="" />
                </div>
                <div class="cover-info sonLocation flex-box">
                  <div class="flex-box">
                    <p class="learnNum-img">
                      <img src="../assets/home/learnNum.png" alt="" />
                    </p>
                    <span>{{ bookItem.viewNum }}</span>
                  </div>
                  <div class="flex-box marLeft-16">
                    <p class="shareNum-img">
                      <img src="../assets/home/share-num.png" alt="" />
                    </p>
                    <span>{{ bookItem.shareNum }}</span>
                  </div>
                </div>
              </div>
              <div class="bookItem-info">
                <p class="bookItem-title">{{ bookItem.bookName }}</p>
                <p class="bookItem-auth">作者：{{ bookItem.bookAuthor }}</p>
              </div>
            </div>
          </div>
        </div>

        <!-- 有章节情况 -->
        <div class="bookList-recommend2" v-else>
          <div class="chapter-box">
            <h3>目录</h3>
            <div class="chapter-list">
              <p class="chapter-item" v-for="chapterItem in chapterList">
                {{ chapterItem }}
              </p>
            </div>
          </div>
          <!-- 推荐 -->
          <div class="right-box">
            <p class="recommend-text">相关推荐</p>
            <div class="rightBook-list">
              <div
                class="rightBook-item hoverFinger"
                v-for="bookItem in bookList"
                @click="toDetail(bookItem.cbid)"
              >
                <p class="rightBook-cover">
                  <img :src="bookItem.coverImg" alt="" />
                </p>
                <div class="rightBook-info parentLocation">
                  <p class="rightBook-title">{{ bookItem.bookName }}</p>
                  <p class="rightBook-auth">{{ bookItem.bookAuthor }}</p>
                  <div class="share-box flex-box sonLocation">
                    <div class="flex-box">
                      <p class="learn-num-icon">
                        <img src="../assets/article/learn-num.png" alt="" />
                      </p>
                      <p>{{ bookItem.viewNum }}</p>
                    </div>
                    <div class="flex-box">
                      <p class="share-icon-icon">
                        <img src="../assets/article/share-icon.png" alt="" />
                      </p>
                      <p>{{ bookItem.shareNum }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { localStorageGet } from "@/utils/localStorage";
import { localStorageSet } from "@/utils/localStorage";
import request from "@/utils/request";

export default {
  data() {
    return {
      cbid: "",
      bookDetail: {},
      bookQrcode: "", // 图书二维码
      chapterList: [], //章节
      bookList: [], //推荐图书列表
      currentPage: 1, // 当前页码
      total: 0,
      qrcode: "", //分享二维码
      qrcodeUrl: "",
      shareDialogVisible: false, //弹框默认不显示
    };
  },
  //接口 一进入页面就执行的
  mounted() {
    window, scrollTo(0, 0);
    this.cbid = this.$route.params.cbid;
    this.getBookDetail();
    this.getBookRecommend();
    // this.isLogin()
    this.getBookQrcode();
  },
  //事件方法
  methods: {
    currentChange(val) {
      this.currentPage = val;
      window.scrollTo(0, 0);
      this.getBookRecommend();
    },
    // 判断是否登录 获取图书二维码
    // isLogin() {
    //     let configInfo = JSON.parse(localStorageGet("userInfo"));
    //     if (configInfo == null) {
    //         return false;
    //     } else {
    //         this.getBookQrcode();
    //     }
    // },

    getBookDetail() {
      //图书详情
      let data = { cbid: this.cbid };
      request({
        url: "/book/detail",
        method: "post",
        data,
      })
        .then((res) => {
          this.bookDetail = res.data;
          this.chapterList = res.data.chapterList;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getBookRecommend() {
      //推荐图书
      // let data = {cateId:}
      request({
        url: "/book/recommend",
        method: "post",
        data: {},
      })
        .then((res) => {
          this.bookList = res.data;
          this.total = res.data.length;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getBookQrcode() {
      //图书二维码
      let data = { cbid: this.cbid };
      request({
        url: "/book/qrcode",
        method: "post",
        data,
      })
        .then((res) => {
          this.bookQrcode = res.data.qrcode;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    toDetail(cbid) {
      this.cbid = cbid;
      let cbida = cbid;
      // console.log(cbida)
      // this.getBookDetail();
      // this.isLogin();

      let pathInfo = this.$router.resolve({
        path: `/bookDetail/${cbida}`,
      });
      // window.open(pathInfo.href, "_blank");
      window.location.href = pathInfo.href;
    },
  },
  //注册子组件
  components: {},
  //计算属性,长得是函数的样子，和data的用法一样，必须要有一个return值 代表计算的结果。
  computed: {},
  //过滤器
  filters: {
    filtime(val) {},
  },
};
</script>

<style lang="less" scoped>
.bookDetail-box {
  padding-top: 40px;
  padding-bottom: 80px;
}

.book-recommend {
  display: flex;

  .bookDetail-cover > img {
    width: 225px;
    height: 300px;
    background: #d8d8d8;
    border-radius: 8px;
  }

  .bookDetail-info {
    padding-left: 44px;

    .bookDetail-title {
      font-size: 16px;
      font-weight: bold;
      color: #3b3d41;
      margin-bottom: 12px;
    }

    .bookDetail-time {
      font-size: 14px;
      color: #6f7174;
      margin-bottom: 16px;
    }

    .btn1 {
      width: 152px;
      height: 48px;
      color: #ffffff;
      line-height: 48px;
      text-align: center;
      background: linear-gradient(
        135deg,
        rgba(0, 140, 255, 0.55) 0%,
        #4676f8 100%
      );
      box-shadow: 1px 2px 4px 0px rgba(0, 56, 187, 0.13);
      border-radius: 24px;
      opacity: 0.6;
      margin-bottom: 28px;
      margin-right: 16px;
    }

    .btn2 {
      width: 152px;
      height: 48px;
      color: #ffffff;
      line-height: 48px;
      text-align: center;
      background: linear-gradient(
        135deg,
        rgba(0, 140, 255, 0.55) 0%,
        #4676f8 100%
      );
      box-shadow: 1px 2px 4px 0px rgba(0, 56, 187, 0.13);
      border-radius: 24px;
      opacity: 0.6;
      margin-bottom: 28px;
    }

    .bookDetail-jieshao {
      font-size: 14px;
      color: #6f7174;
      overflow: hidden;
      text-overflow: ellipsis; //文本溢出显示省略号
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }

    .share-box {
      font-size: 14px;
      color: #6f7174;
      bottom: 0;

      .learn-num-icon > img {
        width: 19px;
        height: 13px;
        margin-right: 4px;
      }

      .share-icon-icon > img {
        width: 19px;
        height: 19px;
        margin-left: 16px;
        margin-right: 4px;
      }

      .share {
        color: #4676f8;
        margin-left: 16px;
      }
    }
  }

  .qrcode-box {
    margin-left: 100px;

    .qrcode-img > img {
      width: 215px;
      height: 215px;
    }

    .qrcode-title {
      font-size: 14px;
      color: #6f7174;
      text-align: center;
      margin-top: 10px;
    }
  }
}

// 推荐
.bookList-recommend {
  padding: 24px;
  // width: 1440px;
  height: 476px;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  margin-top: 50px;
  overflow: hidden;

  .book-list {
    display: flex;
    margin-top: 24px;

    .book-item {
      width: 210px;
      // height: 382px;
      background: #ffffff;
      box-shadow: 0px 2px 15px 0px rgba(48, 49, 51, 0.25);
      border-radius: 8px;
      margin-right: 27px;

      .bookItem-img > img {
        width: 210px;
        height: 300px;
        background: #d8d8d8;
        border-radius: 8px;
        display: block;
      }

      .cover-info {
        padding: 8px 16px;
        font-size: 12px;
        color: #ffffff;
        background: linear-gradient(
          180deg,
          rgba(54, 54, 56, 0) 0%,
          #363638 100%
        );
        border-radius: 0px 0px 8px 8px;
      }

      .bookItem-info {
        padding: 16px;

        .bookItem-title {
          font-size: 16px;
          color: #3b3d41;
          margin-bottom: 12px;
          overflow: hidden;
          text-overflow: ellipsis; //文本溢出显示省略号
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .bookItem-auth {
          font-size: 12px;
          color: #909399;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

// 有章节情况
.bookList-recommend2 {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;

  .chapter-box {
    padding: 16px 24px;
    // width: 900px;
    width: 815px;

    background: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    .chapter-list {
      padding: 33px 24px;

      .chapter-item {
        font-size: 14px;
        color: #303133;
        margin-bottom: 33px;
      }
    }
  }

  .right-box {
    padding: 16px 24px;
    padding: 16px 0px 16px 24px;
    width: 490px;
    height: 800px;
    background: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    // .rightBook-list ::-webkit-scrollbar {
    //     display: none;
    // }

    .rightBook-list {
      height: 700px;
      overflow: hidden;
      overflow-y: scroll;

      .rightBook-item {
        display: flex;
        margin-top: 16px;

        .rightBook-cover > img {
          width: 120px;
          height: 160px;
        }

        .rightBook-info {
          margin-left: 24px;

          .rightBook-title {
            font-size: 16px;
            font-weight: bold;
            color: #3b3d41;
            margin-bottom: 16px;
            overflow: hidden;
            text-overflow: ellipsis; //文本溢出显示省略号
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .rightBook-auth {
            font-size: 14px;
            color: #606266;
            overflow: hidden;
            text-overflow: ellipsis; //文本溢出显示省略号
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .share-box {
            font-size: 14px;
            color: #6f7174;
            bottom: 0;

            .learn-num-icon > img {
              width: 19px;
              height: 13px;
              margin-right: 4px;
            }

            .share-icon-icon > img {
              width: 19px;
              height: 19px;
              margin-left: 16px;
              margin-right: 4px;
            }

            .share {
              color: #4676f8;
              margin-left: 16px;
            }
          }
        }
      }
    }
  }

  // 滚动条
  .rightBook-list::-webkit-scrollbar {
    overflow-y: scroll;
  }

  ::-webkit-scrollbar-thumb {
    height: 109px;
    background: #dcdfe6;
    border-radius: 12px;
  }
}

// 弹框
.el-dialog,
.el-dialog--center {
  border-radius: 20px;

  .qrCode {
    text-align: center;
  }

  .qrCode > img {
    width: 200px;
    height: 200px;
    background-color: #d8d8d8;
  }

  .download-btn {
    width: 120px;
    height: 37px;
    line-height: 37px;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #4676f8;
    font-size: 16px;
    color: #4676f8;
    margin: 0 auto;
  }
}
</style>
