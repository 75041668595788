import router from '@/router';
import { MessageBox } from 'element-ui';
import { Message } from 'element-ui';
import { localStorageGet } from '@/utils/localStorage';

// 白名单
const wihteList = [
    'login',
    'index',
    'homeAndroid_Home',
    'findPassword',
    'course', 'courseware',
    'activity', 'video', 'book',
    'article', 'material',
    'courseDetail',
    'materialDetail',
    'bookDetail',
    'courseDetail', 'videoDetail', 'articleDetail',
];
router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    // if (to.meta.title) {
    //     document.title = to.meta.title;
    // }
    // 白名单：不需要登录可以进入的页面
    if (wihteList.includes(to.name)) {
        next();
        return;
    } else {
        next()  //  不校验登录状态
        // let userInfo = localStorageGet('userInfo');
        // let orgInfoArr = JSON.parse(userInfo);

        // if (!userInfo || Object.keys(orgInfoArr).length == 0 || !(Number(orgInfoArr.loginExpireTime) > new Date().getTime() / 1000)) {//过期了--要让用户重新登陆
        //     MessageBox('登录已过期或失效，请重新登录', '提示', {
        //         confirmButtonText: '确定',
        //         cancelButtonText: '取消',
        //     }).then(() => {
        //         if (from.path == '/index') {
        //             localStorage.removeItem('userInfo');
        //             next(
        //                 router.go(0)
        //             )
        //         } else {
        //             localStorage.removeItem('userInfo');
        //             next({
        //                 name: 'index',
        //                 // query: { redirect: from.fullPath }
        //             })
        //         }

        //     }).catch(() => { });

        // } else {
        //     next()
        // }
        // if (!userInfo) {
        //     localStorage.removeItem('userInfo');
        //     // this.$router.go(0)
        //     // location.reload();
        //     // throw new Error('登录已过期，请重新登录')
        //     MessageBox('1234', '提示', {
        //         confirmButtonText: '确定',
        //         cancelButtonText: '取消',
        //     }).then(() => {
        //         // next((vm) => {
        //         //     router.query.push({
        //         //         path: '/index',

        //         //     })
        //         // })
        //         next({
        //             name: 'index',
        //             query: { redirect: from.fullPath }
        //         })
        //     }).catch(() => { });
        //     next();
        //     throw new Error('当前未登陆，请登录后查看')
        // }

        // if (!(Number(orgInfoArr.loginExpireTime) > new Date().getTime() / 1000)) {
        //     localStorage.removeItem('userInfo');
        //     // this.$router.go(0)
        //     // location.reload();
        //     // throw new Error('登录已过期，请重新登录')
        //     MessageBox('1234', '提示', {
        //         confirmButtonText: '确定',
        //         cancelButtonText: '取消',
        //     }).then(() => {
        //         next((vm)=>{
        //             router.query.push({
        //                 path:'/index',

        //             })
        //         })
        //         // next({
        //             // name: 'index',
        //             // query: { redirect: from.fullPath }
        //         // })
        //     }).catch(() => { });
        // }else{
        //     next();
        // }

        // localStorage.removeItem('userInfo');
        // MessageBox('123', '提示', {
        //     confirmButtonText: '确定',
        //     cancelButtonText: '取消',
        // }).then(() => {
        //     next({
        //         path: '/index',
        //         query: { redirect: from.fullPath }
        //     })
        // }).catch(() => { });
        // next();
    }
});