import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        configData: {},
        searchInfo: {
            searchType: undefined,
            keyWord: undefined,
        }
    },
    getters: {},
    mutations: {
        setConfigData(state, value) {
            state.configData = value
        },
        setSearchInfo(state, value) {
            if (value.keyWord == '') {
                value.keyWord = undefined
            }
            state.searchInfo = value
        }
    },
    actions: {},
    modules: {}
})