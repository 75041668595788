import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '../src/static/css/global.css';
import 'animate.css';
import request from '@/utils/request';
import './permission.js'
import vant from 'vant';
import 'vant/lib/index.css'


// your some.vue
export default {
    // ...
    methods: {
        foo() {
            const preview = this.$imagePreview({
                initIndex: 0,
                images: ['https://yinodimage.oss-cn-hangzhou.aliyuncs.com/20200229004202.jpg'],
            })
        }
    }
}
router.afterEach((to, from, next) => {
    window, scrollTo(0, 0)
})



Vue.prototype.$request = request;
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(vant);



new Vue({
    router,
    store,
    render: function (h) {
        return h(App);
    },
}).$mount('#app');