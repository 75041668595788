<template>
  <div class="backgrounAll">
    <div
      class="onlinePreview-box animate__animated animate__bounce animate__fadeInUp"
    >
      <div class="centre-content">
        <div class="onlinePreview-title">{{ title }}</div>
        <div class="onlinePreview-share">
          格式：{{ format }}
        </div>
        <div class="onlinePreview-info" v-if="coverImg == '微软'">
          <iframe :src="previewUrlBase + dataUrl" frameborder="0"></iframe>
        </div>
        <div class="onlinePreview-info" v-if="coverImg == 'pdf'">
          <object
            type="application/pdf"
            :data="decodeUrl"
            style="width: 100%; height: 100%"
          ></object>
        </div>
        <div class="onlinePreview-info" v-if="coverImg == 'image'">
          <img :src="decodeUrl" alt="" />
        </div>
        <div class="videoPlay" v-if="coverImg == 'video'">
          <div class="player-container">
            <div id="artplayer" class="video-player-box"></div>
          </div>
        </div>
        <div class="videoPlay" v-if="coverImg == 'audio'">
          <div class="player-container">
            <div id="artplayer" class="audio-player-box"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Artplayer from "artplayer";
import request from "@/utils/request";
import { getPreviewType } from "./utils";
// import pdf from 'vue-pdf'

export default {
  // name: 'App',
  // components: {
  //   pdf
  // },
  data() {
    return {
      // 微软预览地址可以预览2、4、5
      previewUrlBase: "https://view.officeapps.live.com/op/view.aspx?src=",
      resId: "",
      format: "", //格式
      /**
        1:png格式  
        2:ppt格式  
        3:pdf格式  
        4:xls格式 
        5:doc格式 
        6:zip格式 
        7:其他格式 
        8:txt格式 
        9:video格式 
        11:audio格式 

        微软：2、4、5
        pdf：3
        image：1
        video：9
        audio：11
       */
      coverImg: "",
      dataUrl: "",
      title: "",
      artplayer: null,
      srcImgUrl:
        "https://xlingtong.oss-cn-beijing.aliyuncs.com/resource/audio-bj.png",
      srcImgUrlPlay:
        "https://xlingtong.oss-cn-beijing.aliyuncs.com/resource/audio-playing-bj.gif",
    };
  },
  //接口 一进入页面就执行的
  mounted() {
    this.resId = this.$route.query.resId;
    this.format = this.$route.query.format;

    this.coverImg = getPreviewType(this.$route.query.coverImg);
    if (this.coverImg == undefined) {
      // 不支持预览该格式
      return;
    }

    this.getKjainGeturl();
  },
  //事件方法
  methods: {
    getKjainGeturl() {
      //获取课件素材的文件地址
      let data = { resId: this.resId };
      request({
        url: "/kejian/geturl",
        method: "post",
        data,
      })
        .then((res) => {
          this.title = res.data.title;
          this.dataUrl = res.data.ossUrl; //浏览
          this.artplayer = new Artplayer({
            container: "#artplayer",
            // url: res.data.ossUrl,
            url: decodeURIComponent(res.data.ossUrl),
            playbackRate: true,
            setting: true, // 设置 （倍速）
            fullscreen: true, //全屏
            // fullscreenWeb: true, //网页全屏
            pip: true, //画中画
            autoplay: true, //自动播放
            muted: true,
            poster:
              "https://xlingtong.oss-cn-beijing.aliyuncs.com/resource/audio-bj.png", //封面图
            backdrop: true, //背景
            theme: "#fff", //进度条颜色
          });
          this.artplayer.on("ready", (...args) => {
            if (this.coverImg == "audio") {
              // this.artplayer.video.style.background = `#ffffff url(${this.srcImgUrlPlay}) fixed center/500px 500px no-repeat`;
              this.artplayer.video.style.background = `#ffffff url(${this.srcImgUrlPlay}) fixed center/1500px no-repeat`;
            }
          });
          this.artplayer.on("play", (...args) => {
            if (this.coverImg == "audio") {
              this.artplayer.video.style.background = `#ffffff url(${this.srcImgUrlPlay}) fixed center/1500px  no-repeat`;
            }
          });
          this.artplayer.on("pause", (...args) => {
            if (this.coverImg == "audio") {
              // this.artplayer.video.style.background = `url(${this.srcImgUrl}) no-repeat`;
              this.artplayer.video.style.background = `#ffffff url(${this.srcImgUrl}) fixed center/1500px no-repeat`;
            }
          });
          // console.log('编码前', this.previewUrl)
          // console.log('编码后', decodeURIComponent(this.previewUrl))
          // console.log('解码', encodeURIComponent(this.previewUrl))
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  //注册子组件
  components: {},
  //计算属性,长得是函数的样子，和data的用法一样，必须要有一个return值 代表计算的结果。
  computed: {
    decodeUrl() {
      return decodeURIComponent(this.dataUrl);
    },
    videoUrl() {
      return decodeURIComponent(this.dataUrl);
    },
  },
  //过滤器
  filters: {
    filtime(val) {},
  },
};
</script>

<style lang="less" scoped>
.onlinePreview-box {
  padding: 40px;
}

.centre-content {
  // width: 820px;
  // width: 1200px;

  .onlinePreview-title {
    font-size: 16px;
    font-weight: bold;
    color: #3b3d41;
    margin-bottom: 16px;
  }

  .onlinePreview-share {
    font-size: 16px;
    color: #606266;
    margin-bottom: 32px;
  }

  .onlinePreview-info {
    // width: 820px;
    width: 1400px;
    height: 750px;
    background: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 20px;
    overflow-y: scroll;
    overflow: hidden;
    position: relative;

    iframe {
      width: 100%;
      height: 100%;
    }

    a {
      display: block;
      width: 1400px;
      height: 750px;
      border: 1px solid red;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-height: 100%;
      max-width: 100%;
      height: 80%;
    }
  }

  .download-btn {
    width: 160px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    color: #ffffff;
    font-size: 18px;
    background: linear-gradient(
      135deg,
      rgba(0, 211, 255, 0.52) 0%,
      #11b6ff 100%
    );
    box-shadow: 1px 2px 4px 0px rgba(0, 56, 187, 0.24);
    border-radius: 6px;
    margin: 40px auto;
  }

  // 视频音频
  .videoPlay {
    // width: 1000px;
    // height: 563px;

    background: #d8d8d8;
    border-radius: 8px;

    .video-player-box {
      height: 563px;
      height: 805px;
    }

    .audio-player-box {
      // height: 950px;
      height: 650px;
    }
  }
}
</style>
