<template>
  <div class="backgrounAll">
    <div
      class="articleDetail-box animate__animated animate__bounce animate__fadeInUp"
    >
      <div class="centre-content">
        <div class="articleDetail-content">
          <div class="articleDetail-header">
            <p class="articleDetail-title">|{{ detail.title }}</p>
            <div class="cover-info">
              <div class="flex-box">
                <p class="learnNum-img">
                  <img src="../assets/article/learn-num.png" alt="" />
                </p>
                <span>{{ detail.viewNum }}</span>
              </div>
              <div class="flex-box marLeft-16">
                <p class="shareNum-img">
                  <img src="../assets/home/share-num2.png" alt="" />
                </p>
                <span>{{ detail.shareNum }}</span>
              </div>
            </div>
          </div>
          <div class="articleDetail-info">
            <p class="box_img" v-html="detail.body"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      artId: "",
      detail: {},
    };
  },
  //接口 一进入页面就执行的
  mounted() {
    // window, scrollTo(0, 0)
    this.artId = this.$route.params.artid;
    this.getArticleDetail();
  },
  //事件方法
  methods: {
    getArticleDetail() {
      let data = { artId: this.artId };
      request({
        url: "/article/detail",
        method: "post",
        data,
      })
        .then((res) => {
          this.detail = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  //注册子组件
  components: {},
  //计算属性,长得是函数的样子，和data的用法一样，必须要有一个return值 代表计算的结果。
  computed: {},
  //过滤器
  filters: {
    filtime(val) {},
  },
};
</script>

<style lang="less" scoped>
.articleDetail-box {
  padding: 40px 0 80px;
}

.articleDetail-content {
  width: 1440px;
  // height: 1000px;
  background: #ffffff;
  box-shadow: 0px 2px 15px 0px rgba(48, 49, 51, 0.25);
  border-radius: 8px;
  padding: 24px;

  .articleDetail-header {
    position: relative;
    height: 120px;
    width: 1440px;
    border-bottom: 1px solid #dcdfe1;

    .articleDetail-title {
      font-size: 22px;
      font-weight: bold;
      color: #3b3d41;
    }

    .cover-info {
      display: flex;
      position: absolute;
      bottom: 24px;
      font-size: 14px;
      color: #6f7174;

      .learnNum-img > img {
        width: 19px;
        height: 13px;
      }

      .share-box {
        margin-left: 24px;

        .share {
          font-size: 14px;
          color: #4676f8;
        }

        .share-text {
          font-size: 14px;
          color: #6f7174;
        }
      }
    }
  }

  .articleDetail-info {
    // width: 1392px;
    padding-top: 20px;
    font-size: 20px;
    color: #606266;

    p {
      font-size: 16px;
      color: #6f7174;
      line-height: 32px;
      text-indent: 2em;
    }

    ::v-deep img {
      margin: 20px auto;
    }

    .box_img > p > img {
      margin: 24px auto;
      border: 1px solid red;
    }
  }
}

// 弹框
.el-dialog,
.el-dialog--center {
  border-radius: 20px;

  .qrCode {
    text-align: center;
  }

  .qrCode > img {
    width: 200px;
    height: 200px;
    background-color: #d8d8d8;
  }

  .download-btn {
    width: 120px;
    height: 37px;
    line-height: 37px;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #4676f8;
    font-size: 16px;
    color: #4676f8;
    margin: 0 auto;
  }
}
</style>
